import Dashboard from '../../components/Dashboard/Dashboard';

const Home = () => {

  return (
    <div className='home'>
      <Dashboard />
    </div>
  )
}

export default Home